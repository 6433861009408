// export const API_URL = "http://localhost:4000/api";
// export const API_URL = "https://ec19f0a2a1000bcc066e0def301792c6.serveo.net/api";
// export const API_URL = "http://192.168.0.148:4000/api";
// export const API_URL ="https://api-kheloindore.swapinfotech.com/api/v1/kheloindore";

// export const IMG_URL = 'http://localhost:4000';
// export const IMG_URL = 'https://ec19f0a2a1000bcc066e0def301792c6.serveo.net';
// export const IMG_URL = 'http://192.168.0.145:4000';
// export const IMG_URL = "https://api-kheloindore.swapinfotech.com";


export const IMG_URL = "https://kheloindore.in";
export const API_URL ="https://kheloindore.in/api";

